/* Main container with a subtle fade-in animation */
.contact-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.8s ease-in;
    background-color: #f0f8ff; /* 背景设置为浅蓝色 */
    border-radius: 10px; /* 添加圆角 */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}

/* Show content once loaded */
body.loaded .contact-content {
    opacity: 1;
}

/* Contact details layout */
.contact-details {
    margin-top: 20px;
}

/* Contact item styles */
.contact-item {
    margin-bottom: 20px;
    padding: 15px;
    border-left: 4px solid #b0d6ff; /* 左侧边框变为浅蓝色 */
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.contact-item:hover {
    background-color: #e6f3ff; /* 悬停时背景色为更浅的蓝色 */
    border-color: #90c6ff; /* 悬停时边框颜色更深 */
}

.contact-item h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #007bff;
}

.contact-item p {
    font-size: 1em;
    color: #333;
}

/* Styling for non-clickable phone and email */
.non-clickable {
    color: #007bff; /* 文字为蓝色 */
    text-decoration: none;
    cursor: default;
}

/* Links styled as clickable text */
.contact-item a {
    color: #007bff;
    text-decoration: none;
    transition: color 0.2s ease;
}

.contact-item a:hover {
    color: #0056b3;
}

.contact-icon {
    margin-right: 8px;
    color: #007bff;
    transition: color 0.3s ease, transform 0.3s ease;
}

.contact-item a:hover .contact-icon {
    color: #0056b3;
    transform: translateY(-2px);
}

/* Responsive design adjustments for smaller screens */
@media (max-width: 768px) {
    .contact-content {
        padding: 10px;
    }
    .contact-item h3 {
        font-size: 1em;
    }
}
