.userInputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 60px);
    width: 25%; /* 用户输入框的宽度 */
    padding: 10px 20px;
    overflow-y: auto;
    margin-top: 30px;
}

/* 新增聊天框的样式 */
.chatContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - 60px);
    width: 10%; /* 聊天框内容的宽度 */
    padding: 10px 20px;
    overflow-y: auto;
    margin-top: 30px;
}


.inputField {
    width: 100%; /* 将输入框宽度设为父容器的100% */
    margin-top: 20px; /* 增加顶部外边距 */
}

/* 输入框和聊天框的容器 */





.inputContainer {
    position: fixed; /* Keep it at the bottom */
    bottom: 0;
    padding: 10px;
    box-sizing: border-box;
    background-color: transparent;
}




/* 聊天框容器 */






.chatboxContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* 从顶部开始排列子元素 */
    align-items: center; /* 水平居中子元素 */
    height: calc(100vh - 60px); /* 总高度减去顶部栏的高度 */
    margin-left: 10%; /* 左边距为 10% */
    margin-right: 10%; /* 右边距为 10% */
}





.messageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 确保内容从左侧开始 */
    height: auto; /* 容器高度自动调整 */
    min-height: 100vh; /* 最小高度，根据需要调整 */
    max-width: 50vw; /* 最大宽度为视口宽度的80% */
    min-width: 50vw; /* 最小宽度为300px，防止在小屏幕上过于压缩 */
    overflow-x: hidden; /* 隐藏水平方向的溢出 */
    overflow-y: auto; /* 垂直方向溢出时显示滚动条 */
    margin-right: 3%;
    padding: 5px 20px; /* 内边距 */

    scrollbar-width: none; /* 对于Firefox, 隐藏滚动条 */
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}





.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center; /* 根据需要可以改为 center */
    align-items: center;
    overflow-x: auto; /* 允许水平滚动 */
}







.customTextField {
    width: 100%; /* Make TextField take up 100% width of its container */
    padding: 0; /* Remove padding */
}
/* Ensures that the TextField's icon stays in place */
.customTextField .MuiInputBase-root {
    position: relative;
    width: 100%;
}

.customTextField .MuiInputBase-input {
    width: 100%;
    padding-right: 45px; /* Add padding to prevent text from hiding behind the icon */
}

/* Adjust scroll bar styling as needed */
.customTextField *::-webkit-scrollbar {
    width: 8px;
}

.customTextField *::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

.customTextField *::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.customTextField *::-webkit-scrollbar-thumb:hover {
    background: #555;
}




@media screen and (max-width: 768px) {
    .messageContainer {
        max-width: 35vh; /* 在手机端时，最大宽度设置为视口高度的75% */
        min-width: 35vh; /* 在手机端时，最小宽度设置为视口高度的75% */
    }

}