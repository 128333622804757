/* CompanyPage.css */

.scrollbar-container::-webkit-scrollbar {
    width: 8px ;
}

.scrollbar-container::-webkit-scrollbar-track {
    background: #f1f1f1 ;
    border-radius: 10px ;
}

.scrollbar-container::-webkit-scrollbar-thumb {
    background: #888 ;
    border-radius: 10px ;
}

.scrollbar-container::-webkit-scrollbar-thumb:hover {
    background: #555 ;
}
