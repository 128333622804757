.scrollable-container::-webkit-scrollbar {
    width: 6px;
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
