body {
    overflow-x: hidden;
    height: 100vh;
    overflow-y: hidden;
    position: relative;
}

.faq-container {
    padding-right: 15px;
    padding: 20px 15px;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background-color: #f0f8ff; /* 背景采用浅蓝色调 */
}

.faq-container::-webkit-scrollbar {
    width: 0;
}

.faq-container {
    scrollbar-width: none;
}

h1 {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #000; /* 标题使用黑色 */
    font-weight: bold; /* 加粗效果 */
}

h1:hover {
    color: #333; /* 标题hover效果使用较深的灰色 */
    transition: color 0.3s ease;
}

.faq-item {
    margin-bottom: 25px;
    border: 1px solid #007bff; /* 边框采用蓝色 */
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.faq-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.faq-title {
    font-size: 1.2em;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 12px;
    border-radius: 5px;
    color: #000; /* 标题颜色采用黑色 */
    font-weight: bold; /* 加粗效果 */
}

.faq-title:hover {
    color: #333; /* 标题hover颜色采用较深的灰色 */
    background-color: rgba(0, 0, 0, 0.05); /* 背景hover时采用浅灰色 */
}

.faq-arrow {
    margin-right: 10px;
    transition: transform 0.3s ease;
    color: #000; /* 箭头颜色采用黑色 */
}

.faq-arrow.expanded {
    transform: rotate(180deg);
}

.faq-content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out, opacity 0.5s ease-in-out;
    max-height: 0;
    opacity: 0;
    padding: 0 10px;
    box-sizing: border-box;
}

.faq-content.expanded {
    max-height: 1000px;
    opacity: 1;
    padding: 10px;
}

.faq-item h3 {
    font-size: 1.3em;
    margin-bottom: 8px;
    color: #000; /* 副标题颜色采用黑色 */
    font-weight: bold; /* 加粗效果 */
}

.faq-item p, .faq-item ul {
    font-size: 1.1em;
    line-height: 1.5;
    color: #333;
}

.faq-item ul {
    padding-left: 20px;
}

.faq-item ul li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .faq-container {
        width: 100%; /* 确保容器宽度为100% */
        padding: 10px; /* 调整内边距以适应手机屏幕 */
        margin-left: 0; /* 确保容器居中 */
        margin-right: 0; /* 确保容器居中 */
        max-width: 300px;
        min-width: 300px;


    }

    .faq-item {
        width: 100%; /* 确保FAQ项宽度为100% */
        padding: 10px; /* 调整FAQ项的内边距以适应手机 */
    }

    .faq-title {
        font-size: 1em; /* 缩小FAQ标题字体大小 */
    }

    .faq-item p, .faq-item ul {
        font-size: 0.9em; /* 缩小FAQ内容字体大小 */
    }
}


