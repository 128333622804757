.footer-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto; /* Allow footer to push to the bottom */
    transform: translateX(0%); /* Adjust X-axis displacement as needed */
    padding-bottom: 20px; /* 增加 padding-bottom 或者 margin-bottom */
}

.footer {
    height: 60px; /* 增加高度 */
    padding: 0 10px; /* 去掉多余的上下 padding */
    display: flex;
    justify-content: space-between;
    align-items: center; /* 保持垂直居中 */
}


.footer-section {
    padding: 10px 0; /* 设置上下 padding，使内容居中 */
    margin: 0 10px;
    text-align: center;
    flex: none;
}


.footer-section a,
.footer-section .link-button {
    color: #343a40;
    text-decoration: none;
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
}

.footer-section a:hover,
.footer-section .link-button:hover {
    text-decoration: underline;
}

.form-select {
    font-size: 16px;
    padding: 5px;
    -webkit-appearance: none; /* Remove default arrow in some browsers */
    -moz-appearance: none; /* Remove default arrow in Firefox */
    appearance: none; /* Remove default arrow */
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="#495057" d="M2 0L0 2h4z"/></svg>') no-repeat right 10px center;
    background-size: 10px;
}

.form-select::-ms-expand {
    display: none; /* Remove default arrow in IE */
}

.modal-overlay {
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    justify-content: center;
    align-items: center;
}

.modal-overlay.show {
    display: flex;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border: none;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-left: 5%; /* Shift content slightly to the right */
}

.close {
    color: #aaa;
    float: right;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
}

.membership-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.membership-column {
    width: 48%;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    background-color: #f9f9f9;
}

.membership-column h3 {
    text-align: center;
    font-size: 18px;
    color: #343a40;
    margin-bottom: 10px;
}

.membership-column ul {
    list-style-type: none;
    padding: 0;
    font-size: 16px;
    color: #495057;
}
@media (max-width: 768px) {
    .footer-section a,
    .footer-section .link-button {
        font-size: 14px; /* 手机端减小字体大小 */
    }
}
