:root {
    --primary-blue: #007bff;
}

html {
    overflow-y: auto;
}

body {
    padding-right: 15px;
    box-sizing: border-box;
    overflow-x: hidden;
}

.news-container {
    padding: 20px;
    background: #f0f8ff; /* 更改背景色为浅蓝色 */
    color: #333;
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    min-width: 1200px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.news-container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.news-header {
    background: var(--primary-blue);
    padding: 20px;
    border-radius: 8px;
    color: #fff;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.news-title {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    color: #ffffff; /* 标题设置为白色 */
}

.header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
}

.date-picker-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
}

.tabs {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ddd;
}

.tab {
    flex: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    font-size: 18px;
    transition: color 0.3s;
}

.tab.active {
    color: var(--primary-blue);
    font-weight: bold;
}

.news-items {
    padding: 10px 0;
}

.news-item {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid #ddd;
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
    max-height: 1000px;
    overflow: hidden;
}

.news-item.expanded {
    max-height: 1000px;
}

.news-item-title {
    font-size: 20px;
    font-weight: bold;
    flex-grow: 1;
}
.news-item:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* 提升阴影效果 */
    transform: translateY(-5px); /* 轻微的位移效果 */
    background-color: #f9f9f9; /* 更改背景色，提供视觉反馈 */
    cursor: pointer; /* 鼠标悬停时显示手形光标 */
}
.news-meta {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.news-summary {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
}

.affected-sectors {
    font-size: 14px;
    color: #fff;
    margin-right: 10px;
}

.news-content {
    margin-top: 10px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 0;
}

.news-content.expanded {
    max-height: 1000px;
}

.no-news {
    text-align: center;
    font-size: 18px;
    color: #666;
    padding: 20px 0;
    background-color: #f0f8ff;
    border-radius: 8px;
    animation: fadeIn 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-news-icon {
    font-size: 40px;
    color: var(--primary-blue);
    margin-bottom: 10px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .news-container {
        padding: 8px;
        margin-left: 45px;
        max-width: 300px;
        min-width: 300px; /* 添加最小宽度，确保在没有新闻时宽度一致 */
        font-size: 14px;
    }

    .news-header {
        padding: 12px;
        margin-bottom: 15px;
    }

    .header-content {
        align-items: flex-start;
        text-align: left;
        margin-top: 10px;
    }

    .date-picker-container {
        justify-content: flex-start;
        margin-top: 5px;
    }

    .news-item {
        padding: 12px;
        margin-bottom: 10px;
    }

    .news-item-title {
        font-size: 8px;
    }

    .news-summary {
        font-size: 14px;
        margin-top: 8px;
    }
    .news-title {
        font-size: 18px!important; /* 移动端字体大小稍微小一些 */
    }

    .affected-sectors {
        font-size: 8px;
    }

    .news-meta {
        gap: 6px;
    }

    .no-news {
        font-size: 14px;
        padding: 15px;
    }


    .news-update-time {
        font-size: 7px!important; /* 调整为较小的时间字体 */
    }

    .sentiment-icon {
        width: 16px!important;
        height: 16px!important; /* 调整为较小的尺寸 */
    }
    .no-news-icon {
        font-size: 28px;
    }

    .mobile-tab-selector {
        display: block;
        margin-bottom: 10px;
    }

    .mobile-tab-dropdown {
        width: 100%;
        padding: 8px;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
}
