.App {
  display: grid;
  grid-template-columns: 240px 1fr; /* Sidebar width and remaining space for content */
  min-height: 100vh;
  text-align: left; /* Align text to the start of the container, not center */
}

.sidebar {
  background-color: #282c34; /* Dark background for the sidebar */
  color: white;
  padding: 20px; /* Padding for aesthetic spacing inside the sidebar */
}




.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* 基于内容量调整对齐方式 */
  padding: 5px 20px; /* 减少顶部和底部的内边距 */
  overflow-y: auto; /* 保留滚动条，但仅在必要时显示 */
  min-height: 100vh; /* 维持最小高度，确保填满视口 */
  max-height: 100vh; /* 设置最大高度，防止超出视口 */
  min-width: 100vw; /* 使用100vw确保填满视口宽度 */
  max-width: 100vw; /* 使用100vw确保填满视口宽度 */
  background-color: #f0f8ff; /* 背景颜色调整 */

}






/* Keep your animation and other styles as is, they do not interfere */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
