.blinking-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: black;
    animation: blink 1s infinite;
    position: absolute;
    left: -20px;
    top: 0;
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.response-container {
    padding: 20px;
    position: relative;
    max-width: 50vw; /* 最大宽度为视口宽度的80% */
    min-width: 50vw; /* 最小宽度为300px，防止在小屏幕上过于压缩 */
}

@media screen and (max-width: 768px) {
    .response-container {
        max-width: 35vh; /* 在手机端时，最大宽度设置为视口高度的75% */
        min-width: 35vh; /* 在手机端时，最小宽度设置为视口高度的75% */
    }
    .response-container h5 {
        font-size: 1.2rem; /* 调整标题字体大小 */
    }

    /* 来源的 icon 和字体缩小 */
    .response-container .MuiAvatar-root {
        width: 18px; /* 调整图标大小 */
        height: 18px;
    }

    .response-container .MuiTypography-body1 {
        font-size: 0.85rem; /* 调整来源字体大小 */
    }

    /* 答案的 icon 和字体缩小 */
    .response-container .MuiAvatar-root + .MuiTypography-body1 {
        font-size: 0.85rem; /* 调整答案字体大小 */
    }

    /* URL 索引和 URL 字体缩小 */
    .response-container .MuiChip-root {
        font-size: 0.65rem; /* 缩小索引数字的字体 */
        min-width: 25px; /* 调整索引圆圈大小 */
        min-height: 25px;
    }

    .response-container .MuiTypography-body1 {
        font-size: 0.85rem; /* 调整URL链接字体大小 */
    }
}