.content-container {
    flex: 1;
    margin-top: 2px;
    overflow-y: auto;
    height: calc(100vh - 150px);
    box-sizing: border-box;
}

/* 默认滚动条样式 */
.content-container::-webkit-scrollbar {
    width: 6px;
}

.content-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.content-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.content-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.page-container {
    width: 100%;  /* 设置容器宽度为 100% */
    max-width: 1200px;  /* 确保在电脑端最大宽度不变 */
    margin: 0 auto;  /* 在电脑端居中对齐 */
    padding: 0 16px; /* 添加内边距，确保内容与边界有一定距离 */
    box-sizing: border-box; /* 确保 padding 和 border 包含在宽度内 */
}
/* 针对手机端的全局 left margin 调整 */
@media (max-width: 768px) {


    .page-container {
        max-width: 380px;  /* 确保在电脑端最大宽度不变 */
        min-width: 380px;
        margin-left: 22px;
    }


    .content-container {
        margin-left: 0; /* 确保子容器不额外增加 margin */
        margin-right: 0; /* 确保子容器不额外增加 margin */
        min-height: 600px!important; /* 调整高度以适应增加的 margin */
        max-height: 600px!important;
        padding-bottom: 80px; /* 增加底部内边距，确保滚动条能够触及到底部 */
        overflow-x: hidden; /* 隐藏横向滚动条 */

    }

    /* 针对具体 UI 元素的字体缩放 */
    h1, h2, h3, h4, h5, h6 {
        font-size: 0.7em; /* 标题字体缩小为原来的 90% */
    }

    p, span, a, button, input, label, textarea {
        font-size: 0.7em; /* 其他文本元素字体缩小为原来的 90% */
    }

    .news-title {
        font-size: 0.65em; /* 新闻标题字体适当缩小 */
    }

    .news-summary {
        font-size: 0.65em; /* 新闻摘要字体适当缩小 */
    }

    .no-news {
        font-size: 0.65em; /* 暂无新闻内容的字体缩小 */
    }

    .MuiBox-root {
        transform: scale(0.96); /* 缩小三分之一 */
        transform-origin: top left; /* 使缩放效果更自然 */
    }
}


@media (max-width: 768px) {
    .MuiPagination-root {
        font-size: 0.8em; /* 缩小整体字体大小 */
    }

    .MuiPagination-ul {
        gap: 4px; /* 缩小分页按钮之间的间距 */
    }

    .MuiPaginationItem-root {
        min-width: 28px; /* 缩小分页按钮的宽度 */
        height: 28px; /* 缩小分页按钮的高度 */
        padding: 0; /* 去掉按钮内边距 */
        font-size: 0.75em; /* 缩小按钮内文字的大小 */
    }

    .MuiPaginationItem-icon {
        font-size: 1.2em; /* 调整分页图标的大小 */
    }

    .MuiPaginationItem-ellipsis {
        font-size: 0.8em; /* 缩小省略号的大小 */
    }
}

/* 针对手机端的收藏按钮样式调整 */
@media (max-width: 768px) {
    .favorite-button {
        transform: translateY(-3px) translateX(5px); /* 调整按钮位置 */
        padding: 4px 8px; /* 缩小按钮的内边距 */
        max-width: 60px!important; /* 调整按钮的最小宽度 */
        max-height: 60px!important; /* 调整按钮的最小高度 */
        min-width: 60px!important; /* 调整按钮的最小宽度 */
        min-height: 60px!important; /* 调整按钮的最小高度 */
        font-size: 0.75em!important; /* 调整按钮文字大小 */
    }

    .favorite-icon {
        font-size: 2em!important; /* 缩小图标大小 */
    }
}