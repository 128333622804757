/* SearchPage.css */
/* 调整搜索模式下拉菜单的按钮样式 */
.search-mode-dropdown .btn {
    background-color: #007acc; /* 与页面浅蓝色搭配的深蓝色 */
    color: white;
    border-radius: 50px!important; /* 四个圆角 */
    padding: 10px 20px; /* 调整内边距 */
    border: none; /* 移除边框 */
    transition: background-color 0.3s ease;
}

.search-mode-dropdown .btn:hover {
    background-color: #005fa3; /* 鼠标悬停时颜色加深 */
}

.search-mode-dropdown .btn:focus {
    outline: none;
    box-shadow: none;
}

/* 调整下拉菜单项的样式 */
.search-mode-dropdown .dropdown-item {
    border-radius: 12px; /* 下拉菜单的项也有圆角 */
    padding: 10px 15px;
    transition: background-color 0.2s ease;
}

.search-mode-dropdown .dropdown-item:hover {
    background-color: #d0e8ff; /* 鼠标悬停时的颜色 */
}

.textarea-actions {
    background-color: #f0f8ff; /* 设置为页面统一的浅蓝色 */
}

.search-mode-dropdown {
    background-color: #f0f8ff; /* 设置为页面统一的浅蓝色 */
}

.container {
    display: flex;
    height: 80vh;
    justify-content: center;
    align-items: center;
    transform: translateY(-5%);
    background-color: #f0f8ff; /* 背景颜色调整 */
}

.textarea-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    border: 2px solid #d3e8ff; /* 边框颜色调整为浅蓝色 */
    border-radius: 4px; /* 调整边框圆角 */
    padding-bottom: 60px; /* 为底部UI元素腾出空间 */
    background-color: #f0f8ff; /* 文本区域背景颜色 */
}

.textarea {
    background-color: #ffffff; /* 默认显示白色 */
    color: #333; /* 调整文本颜色为深灰色 */
    padding: 12px;
    font-size: 1.25rem;
    width: 100%;
    height: 100px;
    resize: none;
    overflow-y: auto;
    outline: none;
    border: none; /* 移除单独的边框 */
    border-radius: 4px 4px 0 0; /* 调整边框圆角 */
    box-sizing: border-box;
    max-height: 300px;
}

.textarea::placeholder {
    color: #888888; /* 占位符显示为灰色 */
}


.textarea::-webkit-scrollbar {
    width: 6px;
}
/* scroll bar */
.textarea::-webkit-scrollbar-track {
    background: #e0f0ff; /* 替换为浅蓝色背景，与页面整体颜色搭配 */
}

.textarea::-webkit-scrollbar-thumb {
    background: #a0d8ff; /* 替换为柔和的浅蓝色，和背景色相协调 */
    border-radius: 10px;
}

.textarea::-webkit-scrollbar-thumb:hover {
    background: #80c4ff; /* 鼠标悬停时颜色稍微加深 */
}
/* scroll bar */
.h4 {
    font-size: 2.5rem; /* 增加字体大小 */
    color: #000000; /* 将颜色改为黑色 */
    font-weight: bold; /* 使字体更粗一些 */
}


.textarea-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-top: none; /* 移除顶部边框 */
}

.search-button {
    background-color: #007acc; /* 按钮背景颜色 */
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.5rem;
    width: 40px;
    height: 40px;
}

.search-button:hover {
    background-color: #005fa3; /* 悬停时颜色加深 */
}

.search-button:disabled {
    background-color: #ccc;
    cursor: default;
}

.textarea-container:focus-within {
    border-color: #007acc; /* 调整为蓝色焦点边框 */
}

.textarea-container:focus-within, .textarea:focus, .search-mode-dropdown .btn:focus, .search-button:focus {
    outline: none; /* 移除默认的蓝色轮廓 */
}

.preview-questions {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 三列 */
    grid-template-rows: repeat(2, auto); /* 两行 */
    gap: 1rem;
    width: 100%;
    max-width: 800px;
}

.preview-question {
    padding: 0.75rem 1rem;
    background-color: #e6f2ff;
    border: 1px solid #cde4ff;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 auto;
    min-width: 250px;
}

.preview-question:hover {
    background-color: #d0e8ff;
    transform: scale(1.05); /* 鼠标悬停时放大 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加轻微阴影效果 */
}

.preview-question:active {
    transform: scale(0.98); /* 点击时稍微缩小 */
    background-color: #c0d8ff; /* 点击时背景颜色变化 */
}

.search-mode-dropdown .btn {
    display: flex;
    align-items: center;
    border: none; /* 移除单独的边框 */
    border-radius: 0; /* 与文本框保持一致 */
}

.search-mode-dropdown .btn:focus,
.textarea-container:focus-within .search-mode-dropdown .btn,
.textarea-container:focus-within .search-button {
    border-color: #007acc; /* 焦点颜色调整为蓝色 */
    outline: none; /* 移除默认的蓝色轮廓 */
}

/* 新增：移除蓝色轮廓 */
textarea:focus, button:focus, input:focus, select:focus {
    outline: none !important;
    box-shadow: none !important;
}

* {
    outline: none !important;
}

.container {
    overflow: hidden; /* 确保父容器不会显示滚动条 */
}

/* 移动设备样式 */
@media (max-width: 768px) {
    .container {
        transform: translateY(-10%);
    }

    .textarea-container {
        max-width: 100%; /* 移动设备上使用全宽 */
        padding-bottom: 50px; /* 调整底部间距以适应较小屏幕 */
    }

    .textarea {
        font-size: 1rem; /* 调整字体大小 */
        padding: 10px;
    }

    .textarea-actions {
        padding: 8px; /* 调整底部按钮区域的内边距 */
    }

    .search-button {
        font-size: 1.25rem; /* 调整按钮大小 */
        width: 35px;
        height: 35px;
    }

    .preview-questions {
        grid-template-columns: repeat(2, 1fr); /* 调整为两列 */
        grid-template-rows: repeat(3, auto); /* 调整为三行 */
        gap: 0.5rem; /* 缩小间距 */
    }

    .preview-question {
        font-size: 0.75rem; /* 调整预览问题的字体大小 */
        min-width: 100px; /* 减小最小宽度 */
        padding: 0.5rem; /* 减小内边距 */
    }

    h4 {
        font-size: 1.5rem; /* 调整标题大小 */
    }
}

/* 移动设备下的下拉菜单样式 */
@media (max-width: 768px) {
    .dropdown-menu {
        width: 90%;  /* 将宽度调整为90% */
        max-width: 90vw;  /* 确保panel不会超过视口宽度的90% */
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        padding: 6px;
        box-sizing: border-box;
    }

    .dropdown-item {
        font-size: 12px; /* 调整字体大小 */
        padding: 6px 8px;
        width: 100%;
        min-height: 40px;
    }

    .search-mode-dropdown .dropdown-menu {
        width: 60%;  /* 将宽度调整为60% */
        max-width: 60vw;
    }

    .search-mode-dropdown .btn-secondary {
        padding: 6px;
    }


    .search-mode-dropdown .col-6 {
        width: 100%;
        margin-bottom: 4px;
    }

    .search-mode-dropdown .dropdown-item small {
        font-size: 10px; /* 缩小描述部分的字体大小 */
    }
    .search-mode-dropdown .dropdown-item {
        font-size: 0.9rem; /* 增大下拉菜单项的文本字体 */
        padding: 5px 8px; /* 调整内边距 */
    }
    .container h4 {
        font-size: 1.5rem !important; /* 手机端调整字体大小 */
    }

}
