.calendar-picker {
    font-family: Arial, sans-serif;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 10px;
    position: absolute;
    z-index: 1000;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.weekday-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
}

.days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.days-grid span {
    text-align: center;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
}

.days-grid span:hover {
    background-color: #f0f0f0;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
}
